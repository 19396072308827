// primeng
import { PrimengSharedModule } from 'src/app/shared-primeng.module'

import { NgxPermissionsModule } from 'ngx-permissions'
// Angular
import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
// Translation
import { TranslateModule } from '@ngx-translate/core'
// Loading bar
import { LoadingBarModule } from '@ngx-loading-bar/core'
// FilterBy in HTML
import { NgPipesModule } from 'ngx-pipes'
// Modal
import { ModalModule } from 'ngx-bootstrap/modal'
// Perfect Scrollbar
//import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg-2'
// Material
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
// Core Module
import { CoreModule } from 'src/app/core/core.module'
import { HeaderComponent } from './header/header.component'
import { HeadercComponent } from './header/headerc.component'
import { AsideLeftComponent } from './aside/aside-left.component'
import { FooterComponent } from './footer/footer.component'
import { FootercComponent } from './footer/footerc.component'
import { SubheaderComponent } from './subheader/subheader.component'
import { BrandComponent } from './brand/brand.component'
import { TopbarComponent } from './header/topbar/topbar.component'
import { TopbarcComponent } from './header/topbar/topbarc.component'
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component'
import { NomeEmpresaComponent } from './header/nome-empresa/nome-empresa.component'
import { PartialsModule } from 'src/app/views/partials/partials.module'
import { BaseComponent } from './base/base.component'
import { PagesModule } from 'src/app/views/pages/pages.module'
import { HtmlClassService } from './html-class.service'
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component'
import { EscolhaEmpresaComponent } from './aside/escolha-empresa/escolha-empresa.component'
import { EscolhaEstabelecimentoComponent } from './aside/escolha-estabelecimento/escolha-estabelecimento.component'
import { EscolhaDataMovimentoComponent } from './aside/escolha-data-movimento/escolha-data-movimento.component'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { TituloListasModule } from 'src/app/core/shared/titulo-listas/titulo-listas.module'
import { TooltipModule } from 'ngx-bootstrap/tooltip'

export const bsDatepickerModuleForRoot: ModuleWithProviders<BsDatepickerModule> = BsDatepickerModule.forRoot()
export const modalModuleForRoot: ModuleWithProviders<ModalModule> = ModalModule.forRoot()
export const ngxPermissionsModuleForChild: ModuleWithProviders<NgxPermissionsModule> = NgxPermissionsModule.forChild()
export const translateModuleForRoot: ModuleWithProviders<TranslateModule> = TranslateModule.forChild()

@NgModule({
    declarations: [
        BaseComponent,
        FootercComponent,
        FooterComponent,

        // headers
        HeaderComponent,
        HeadercComponent,
        BrandComponent,
        HeaderMobileComponent,
        // subheader
        SubheaderComponent,
        // topbar components
        TopbarComponent,
        TopbarcComponent,
        // nome empresa component
        NomeEmpresaComponent,
        // escolha movimento
        EscolhaDataMovimentoComponent,
        // escolha empresa
        EscolhaEmpresaComponent,
        // escolha estabelecimento
        EscolhaEstabelecimentoComponent,
        // escolhe extrato
        // aside left menu components
        AsideLeftComponent,
        // horizontal menu components
        MenuHorizontalComponent,
    ],
    exports: [
        BaseComponent,
        FooterComponent,
        // headers
        HeaderComponent,
        BrandComponent,
        HeaderMobileComponent,
        // subheader
        SubheaderComponent,
        // topbar components
        TopbarComponent,
        // nome empresa component
        NomeEmpresaComponent,
        // escolha movimento
        EscolhaDataMovimentoComponent,
        // escolha empresa
        EscolhaEmpresaComponent,
        // escolha estabelecimento
        EscolhaEstabelecimentoComponent,
        // escolhe extrato
        // aside left menu components
        AsideLeftComponent,
        // horizontal menu components
        MenuHorizontalComponent,
    ],
    providers: [
        HtmlClassService,
    ],
    imports: [
        PrimengSharedModule,
        bsDatepickerModuleForRoot,
        TituloListasModule,
        TooltipModule,
        CommonModule,
        RouterModule,
        ngxPermissionsModuleForChild,
        PagesModule,
        PartialsModule,
        CoreModule,
//        PerfectScrollbarModule,
        FormsModule,
        MatProgressBarModule,
        MatTabsModule,
        MatButtonModule,
        MatTooltipModule,
        translateModuleForRoot,
        LoadingBarModule,
        InlineSVGModule,
        // ng-bootstrap modules
        // escolha empresa
        NgPipesModule,
        modalModuleForRoot
    ]
})
export class ThemeModule {
}
