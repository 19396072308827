import { Injectable } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { EstabelecimentoService } from '../../services/estabelecimento.service'
import { EmpresaContexto } from '../../models/empresa/empresaContexto'
import { Retorno } from '../../models/retorno'

@Injectable({
  providedIn: 'root'
})
export class ApoioService {
  private subs = new Subscription();
  private _escritorioContabil = new BehaviorSubject<EmpresaContexto>(null);
  escritorioContabil = this._escritorioContabil.asObservable();

  constructor(
    private estabelecimentoService: EstabelecimentoService
  ) { }

  async obterCodigoEscritorio(): Promise<EmpresaContexto> {
    return new Promise((resolve, reject) => {
      this.subs.add(this.estabelecimentoService.pegarEstabelecimentoEscritorio().subscribe({
        next: (retorno: Retorno<any>) => {
          if (retorno.valido  && retorno.retorno) {
            let empresa: EmpresaContexto = {
              id: retorno.retorno.empresaId,
              clienteId: retorno.retorno.clienteId,
              nome: retorno.retorno.nome,
              cpfCnpj: retorno.retorno.cpfCnpj,
              tipo: retorno.retorno.tipo,
              planoId: null,
              matrizId: null,
              inativa: retorno.retorno.inativa,
              dataBloqueio: null, // Este campo é opcional
              estabelecimentos: []
            }

            sessionStorage.setItem('ID_ESCRITORIO', empresa.id.toString())
            resolve(empresa)
          } else {
            sessionStorage.removeItem('ID_ESCRITORIO')
            resolve(null) // Ou outro valor padrão
          }
        },
        error: () => {
          sessionStorage.removeItem('ID_ESCRITORIO')
          reject(null) // Rejeita com 0 em caso de erro
        }
      }));
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

