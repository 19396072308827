<!-- begin:: Footer -->
<div class="footer bg-white py-1 d-flex flex-lg-column border-top border-secondary" id="kt_footer" [ngClass]="footerClasses">
	<div [ngClass]="footerContainerClasses" class=" d-flex flex-column flex-md-row align-items-center justify-content-between">
		<span class="text-bold">{{ empresa }}</span>
    <div class="text-dark">
			<span class="text-muted font-weight-bold mr-2">{{today | date:'yyyy'}} &copy;</span>
			<a href="{{ link }}" target="_blank" class="text-dark-75 text-hover-primary">{{ representante }}</a>
		</div>
	</div>
</div>
<!-- end:: Footer -->
