import { Component, Input } from '@angular/core'
import { FormControl, FormGroup, FormArray } from '@angular/forms'
import { classeErro } from 'src/app/constantes'

@Component({
  selector: 'app-erro-campo',
  template: `<p *ngIf="deveMostrarMensagemErro()" class="text-danger {{classeDeErro}}">
    {{ mensagemErro }}
  </p>`
})
export class ErroCampoComponent {
  classeDeErro: string
  @Input() formControl: FormControl | FormGroup | FormArray;

  constructor() { this.classeDeErro = classeErro }

  public deveMostrarMensagemErro(): boolean {
    return !!(this.formControl?.invalid && this.formControl?.touched);
  }

  public get mensagemErro(): string | null {
    const errors = this.formControl?.errors;
    if (!errors) return null;

    if (errors.required) return 'Campo obrigatório';
    if (errors.mask && errors.mask.requiredMask === "00.000.000/0000-00") return "CNPJ inválido";
    if (errors.mask && errors.mask.requiredMask === "000.000.000-00") return "CPF inválido";
    if (errors.minlength) return `Deve ter no mínimo ${errors.minlength.requiredLength} caracteres`;
    if (errors.maxlength) return `Deve ter no máximo ${errors.maxlength.requiredLength} caracteres`;
    if (errors.min) return `Deve ser igual ou maior que ${errors.min.min}`;
    if (errors.email) return 'Formato de email inválido';
    if (errors.bsDate) return 'Data inválida';
    if (errors.erro) return errors.erro.value;
    if (errors.errorMessage) return errors.errorMessage;

    return 'Por favor preencha o campo corretamente';
  }

}
